import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Email = makeShortcode("Email");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Datenschutzerklärung für die Website `}<a parentName="h2" {...{
        "href": "http://www.hitabis.de"
      }}>{`www.hitabis.de`}</a></h2>
    <p>{`Hitabis nimmt den Schutz Ihrer persönlichen Daten sehr ernst. Die folgenden Punkte geben Ihnen einen Überblick über Zweck, Art und Umfang der durch Hitabis erhobenen und verarbeiteten Daten.`}</p>
    <p>{`In der Datenschutzerklärung werden Sie darüber aufgeklärt, welche Ihrer Daten im welchen Umfang, von wem und zu welchen Zwecken verarbeitet werden. Weiter werden Sie über Ihre Rechte gegenüber Hitabis, insbesondere die Widerrufs- und Auskunftsrechte, aufgeklärt.`}</p>
    <p>{`Durch die Nutzung der Angebote erlauben Sie Hitabis die hier beschriebene Datennutzung.`}</p>
    <p><strong parentName="p">{`1. Verantwortlicher`}</strong>{`
Verantwortliche Stelle im Sinne der Datenschutz-Grundverordnung (Art. 4 Abs. 7 DSGVO) und der übrigen nationalen Datenschutzgesetze und datenschutzrechtlicher Bestimmungen ist:`}</p>
    <p>{`Hitabis GmbH
Hildegard-Jadamowitz-Straße 26
10243 Berlin
T: +49 30 92271831
E: `}<Email email="info@hitabis.de" mdxType="Email" />{`
vollständige Anbieterkennung: `}<a parentName="p" {...{
        "href": "/impressum/"
      }}>{`/impressum/`}</a></p>
    <p>{`Der Datenschutzbeauftragte kann wie folgt kontaktiert werden:`}</p>
    <p>{`Hitabis GmbH
-Datenschutzbeauftragter-
Hildegard-Jadamowitz-Straße 26
10243 Berlin
E: `}<Email email="datenschutzbeauftragter@hitabis.de" mdxType="Email" /></p>
    <p>{`Diese Kontaktdaten sind damit relevant für alle Fragen datenschutzrechtlicher Natur zu dieser Website sowie für alle datenschutzrechtlichen Ansprüche Ihrerseits.`}</p>
    <p><strong parentName="p">{`2. Personenbezogene Daten und deren Verwendung bei der Kontaktaufnahme`}</strong>{`
Sie können die Website von Hitabis besuchen, ohne Angaben zu Ihrer Person zu machen. Personenbezogene Daten werden nur erhoben, wenn Sie diese im Rahmen Ihres Besuchs des Internetauftritts von Hitabis freiwillig mitteilen, z.B. indem Sie das Kontaktformular nutzen.
Rechtsgrundlage zur Verarbeitung Ihrer personenbezogenen Daten, die Sie uns im Rahmen der Kontaktaufnahme übermitteln und die wir im Rahmen unserer Leistungen an Sie verarbeiten, ist Art. 6 Abs.1 lit. b DSGVO sowie Art. 6 lit. f DSGVO, im Übrigen Art. 6 Abs. 1 lit. f DSGVO.`}</p>
    <p><strong parentName="p">{`Kontaktaufnahme per E-Mail`}</strong></p>
    <p>{`Im Fall der Kontaktaufnahme per E-Mail werden die Daten, die Sie uns per E-Mail senden, an uns übermittelt und von uns gespeichert. Hierbei geht es um die folgenden Daten:`}</p>
    <ul>
      <li parentName="ul">{`Ihre E-Mail-Adresse`}</li>
      <li parentName="ul">{`den Zeitpunkt der Kontaktaufnahme`}</li>
      <li parentName="ul">{`Gegebenenfalls weitere Daten, wenn Sie diese angeben.`}</li>
    </ul>
    <p><strong parentName="p">{`Kontaktaufnahme über das Kontaktformular der Webseite`}</strong></p>
    <p>{`Im Fall der Kontaktaufnahme über das Kontaktformular der Webseite werden die Daten, die Sie in das Kontaktformular eintragen, an uns übermittelt und von uns gespeichert.`}</p>
    <p>{`Hierbei geht es um die folgenden Daten:`}</p>
    <p>{`Damit wir mit Ihnen in Kontakt treten können, wenn wir Ihr Anliegen bearbeiten, sind die folgenden Angaben Pflichtfelder innerhalb des Kontaktformulars:`}</p>
    <ul>
      <li parentName="ul">{`Ihr Name`}</li>
      <li parentName="ul">{`Ihre E-Mail-Adresse`}</li>
      <li parentName="ul">{`Ihre Nachricht an uns`}</li>
    </ul>
    <p>{`Zusätzlich können Sie uns, wenn Sie dies wünschen, weitere Informationen über Ihr Anliegen mitteilen. Dies betrifft die folgenden Datenfelder:`}</p>
    <ul>
      <li parentName="ul">{`Ihre Telefonnummer`}</li>
      <li parentName="ul">{`Ihre Faxnummer`}</li>
      <li parentName="ul">{`Name Ihrer Firma`}</li>
      <li parentName="ul">{`Name Ihrer Abteilung`}</li>
      <li parentName="ul">{`Ihre Webseite`}</li>
      <li parentName="ul">{`Thema der Anfrage (Interesse)`}</li>
      <li parentName="ul">{`Gegebenenfalls weitere Daten, wenn Sie diese angeben`}</li>
    </ul>
    <p>{`Wenn Sie mit uns in Kontakt treten gehen wir davon aus, dass Sie Interesse an unseren Angeboten haben, insofern ist die Rechtsgrundlage für die Datenverarbeitung Art. 6 Abs. 1 lit. b DSGVO, im Übrigen Art. 6 Abs. 1 lit. f DSGVO.`}</p>
    <p>{`Diese an uns übermittelten Daten werden nur für die Durchführung der Konversation verwendet und nicht an Dritte weitergegeben.`}</p>
    <p>{`Wir werden diese Daten löschen, wenn sie für den jeweiligen Zweck nicht weiter benötigt werden. D. h., wenn der Austausch per E-Mail bzw. per Telefon mit Ihnen beendet ist und wir Ihr Anliegen vollständig bearbeitet haben.`}</p>
    <p>{`Sie haben jederzeit die Möglichkeit, die Einwilligung zur Verarbeitung Ihrer Daten zu widerrufen. Bitte wenden Sie sich hierzu an die oben genannten Kontaktmöglichkeiten. Im Falle eines Widerrufs werden alle Ihre personenbezogenen Daten, die zum Zweck der Kontaktaufnahme gespeichert wurden, gelöscht.`}</p>
    <p><strong parentName="p">{`3. Logfiles`}</strong>{`
Bei jedem Aufruf unserer Website erfassen wir automatisiert Daten und Informationen vom dem Computersystem, welches Sie zum Aufruf der Website verwenden. Alle Logfiles werden maximal 14 Tage gespeichert und nach Ablauf dieser Frist vollständig gelöscht.`}</p>
    <p>{`Folgende Daten werden hierbei erhoben:`}</p>
    <ul>
      <li parentName="ul">{`Datum und Uhrzeit der Anfrage`}</li>
      <li parentName="ul">{`angefragte URL (z.B. /datenschutz/)`}</li>
      <li parentName="ul">{`Informationen zum vom User verwendeten Browser und Betriebssystem`}</li>
      <li parentName="ul">{`Ihre IP-Adresse (anonymisiert)`}</li>
    </ul>
    <p>{`Die Daten werden in den Logfiles unseres Systems gespeichert. Nicht hiervon betroffen sind Daten, die die Zuordnung der Daten zu Ihnen ermöglichen. IP-Adressen werden vor der Speicherung in Log-Files grundsätzlich maskiert. Eine Speicherung der genannten Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet nicht statt. Die Rechtsgrundlage für die vorübergehende Speicherung dieser Daten ist Art. 6 Abs. 1 lit. f DSGVO.`}</p>
    <p>{`Die Erfassung der Daten zur Bereitstellung unserer Website und die Speicherung der Daten in Logfiles ist für den Betrieb unserer Website zwingend erforderlich. Daher besteht für Sie auch keine Widerspruchsmöglichkeit.`}</p>
    <p><strong parentName="p">{`4. Datenweiterleitung an Dritte`}</strong></p>
    <p>{`Die Daten der Nutzer werden nur dann an Dritte weitergeleitet, wenn dies gesetzlich erlaubt ist oder Sie in die Weiterleitung eingewilligt haben. Das ist zum Beispiel der Fall, wenn die Weiterleitung der Daten der Erfüllung vertraglicher Verpflichtungen gegenüber dem Nutzer dient oder falls die Daten von zuständigen Stellen wie Strafverfolgungsbehörden angefordert werden.`}</p>
    <p>{`Ihre personenbezogenen Daten werden keineswegs an Dritte zu Werbezwecken oder zwecks Erstellung von Nutzerprofilen verkauft oder weiter geleitet.`}</p>
    <p><strong parentName="p">{`5. Löschung Ihrer Daten`}</strong></p>
    <p>{`Ihre gespeicherten personenbezogenen Daten werden von uns gelöscht, sobald wir die Daten zu den Zwecken, zu welchen sie gespeichert worden sind, nicht mehr benötigen oder Sie die Löschung wünschen. Anstelle der Löschung erfolgt in den gesetzlich vorgesehenen Fällen (Aufbewahrungsfristen, schutzwürdige Interessen des Betroffenen, Ausschluss der Löschung aufgrund der Art der Speicherung) die Einschränkung der Verarbeitung, d.h. die weitere Verarbeitung der Daten wird von uns gestoppt und zukünftig effektiv unterbunden.`}</p>
    <p><strong parentName="p">{`6. Ihre Rechte im Zusammenhang mit der Erhebung personenbezogener Daten`}</strong></p>
    <p>{`Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:`}</p>
    <ul>
      <li parentName="ul">{`Recht auf Auskunft,`}</li>
      <li parentName="ul">{`Recht auf Berichtigung oder Löschung,`}</li>
      <li parentName="ul">{`Recht auf Einschränkung der Verarbeitung,`}</li>
      <li parentName="ul">{`Recht auf Widerspruch gegen die Verarbeitung,`}</li>
      <li parentName="ul">{`Recht auf Datenübertragbarkeit.`}</li>
    </ul>
    <p>{`Zur Wahrnehmung dieser Rechte haben Sie jederzeit die Möglichkeit, sich an Hitabis zu wenden: `}<Email email="info@hitabis.de" mdxType="Email" />{`
Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.`}</p>
    <p><strong parentName="p">{`7. Widerspruch oder Widerruf`}</strong></p>
    <p>{`Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.`}</p>
    <p>{`Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, beispielsweise sofern wir die Verarbeitung auf Art. 6 lit. f DSGVO stützen. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.`}</p>
    <p>{`Selbstverständlich können Sie die Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und Datenanalyse jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie uns unter folgenden Kontaktdaten informieren:`}</p>
    <p>{`Hitabis GmbH
Hildegard-Jadamowitz-Str. 26,
10243 Berlin,`}</p>
    <Email email="info@hitabis.de" mdxType="Email" />
    <p><strong parentName="p">{`8. Links auf fremde Angebote`}</strong>{`
Unsere Website kann Links auf Angebote Dritter enthalten. Wenn Sie einem derartigen Link folgen, gilt dafür nicht mehr unsere Datenschutzerklärung, sondern die des Drittanbieters.`}</p>
    <p><strong parentName="p">{`9. Änderungen der Datenschutzerklärung`}</strong>{`
Hitabis behält sich vor, die Datenschutzerklärung zu ändern, um sie an eine geänderte Rechtslage oder bei Änderungen des Dienstes sowie der Datenverarbeitung anzupassen. Es wird daher darum gebeten, im Zweifel die Datenschutzerklärung erneut zu prüfen, bevor Sie personenbezogene Daten an Hitabis übermitteln. Änderungen an der Datenschutzerklärung werden in geeigneter Form auf der Webseite publiziert.`}</p>
    <p>{`Stand Mai 2018`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      